<template>
  <div class="lock-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    <el-dialog title="编辑安装位置" :visible.sync="dialogVisible" :close-on-click-modal="false" width="450px">
      <div class="alertTiltes">
        <div class="ul_div">
          <div class="ul_li_one">设备类型：</div>
          <div class="ul_li_thrwe">
            <span v-text="dialogList.Name"></span>
          </div>
        </div>
        <div class="ul_div">
          <div class="ul_li_one">MAC：</div>
          <div class="ul_li_thrwe">
            <span v-text="dialogList.mac"></span>
          </div>
        </div>
        <div class="ul_div">
          <div class="ul_li_one">绑定房源：</div>
          <div class="ul_li_thrwe">
            <el-select v-model="dialogList.apartmentID" filterable placeholder="请选择房源" disabled>
              <el-option v-for="item in selectList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="ul_div">
          <div class="ul_li_one">安装位置：</div>
          <div class="ul_li_thrwe">
            <el-input type="textarea"  placeholder="请输入安装位置" maxlength="200" show-word-limit class="textarea" v-model="dialogList.location"></el-input>
          </div>
        </div>
        <div style="text-align: center;">
          <el-button @click="onRester()">取消</el-button>
          <el-button type="primary" @click="onSubmit()">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  let validMAC = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持输入大小写字母，数字，限制1~32位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("MAC格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        dialogVisible: false,
        selectList: [{
          label: '全部',
          value: '',
        }],
        dialogList: {
          id: "",
          Name: "",
          mac: "",
          ip: "",
          apartmentID: "",
          location: ""
        },
        opt: {
          title: "蓝牙公钥列表",
          search: [{
              key: "mac",
              label: "MAC",
              maxlength: 32,
              rules: [{
                validator: validMAC,
                trigger: ['blur']
              }]
            },
            {
              key: "location",
              label: "安装位置",
              maxlength: 200,
            }
          ],
          columns: [{
              label: "MAC",
              key: "mac"
            },
            {
              label: "设备类型",
              key: "Name"
            },
            {
              label: "绑定房源",
              key: "apartmentName"
            },
            {
              label: "安装位置",
              key: "locationStr",
              width: 150
            },
            {
              label: "最近一次使用时间",
              key: "lastUseTimeStr"
            },
            {
              label: "电量",
              key: "batteryStr"
            },
            {
              label: "ICCID",
              key: "iccid"
            },
            {
              label: "借用状态",
              key: "borrowStateStr"
            },
            // {
            //   label: "电量",
            //   key: "batteryPowerStr"
            // },
            {
              label: "操作",
              key: "action",
              type: "action",
              opt: {
                list: [{
                  label: "查看记录",
                  on(row) {
                    _this.$router.push("/main/BluetoothKey/log/" + row.id);
                  }
                }]
              }
            }
          ],
          buttons: [{
            type: 1,
            on(data) {
              // _this.$router.push("/main/apartment/detail/edit/" + data.id);
              let row = data.data;
              console.log(row);
              console.log(row);
              _this.dialogVisible = true;
              _this.dialogList.Name = row.Name;
              _this.dialogList.id = row.id;
              _this.dialogList.mac = row.mac;
              _this.dialogList.ip = row.ip;
              // _this.dialogList.apartmentID = row.apartmentId;
              _this.dialogList.location = row.location;
            }
          }, ]
        }
      };
    },
    created() {
      console.log("lock-list created!!");
      // this.getApartmentList();
    },
    deactivated() {
      this.dialogVisible = false;
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm
        };
        this.post("device-service/L2/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.Name = ["蓝牙网关", "W7", "L2蓝牙公钥", '', 'W8'][item.type - 1];
            item.borrowStateStr = item.borrowState ? ["未借出", "已借出"][item.borrowState] : "未借出";
            // item.batteryPowerStr = item.batteryPower?item.batteryPower+'%':"";
            item.action = [true, true];
            item.apartmentName = '全部';
            item.locationStr = item.location ? item.location : '--';
            item.lastUseTimeStr = item.lastUseTime ? item.lastUseTime : '--';
            item.batteryStr = item.battery ? item.battery + '%' : '--';
            //  { label: "安装位置", key: "locationStr", width: 150 },
            // { label: "最近一次使用时间", key: "lastUseTimeStr" },
          }
          opt.cb(res.data);
        });
      },
      //获取房源列表
      getApartmentList() {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
            null :
            this.cache.getLS("userInfo")["userId"]
        };
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(
          res => {
            let returnData = {};
            res.forEach(data => {
              returnData[data.id] = data.name;
            });
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            this.selectList = res;
          }
        );
      },
      onRester() {
        this.dialogVisible = false;
      },
      onSubmit() {
        console.log(this.dialogList);
        // if (this.dialogList.apartmentID == "") {
        //   return this.$message({
        //     message: "请选择房源!",
        //     type: "warning"
        //   });
        // } else if (this.dialogList.apartmentID == "") {
        //   return this.$message({
        //     message: "请输入安装位置!",
        //     type: "warning"
        //   });
        // } else {
        // let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,64}$/;
        // if (this.dialogList.location && !reg.test(this.dialogList.location)) {
        //   return this.$message({
        //     message: "安装位置格式错误！",
        //     type: "warning"
        //   });
        // }
        var dto = {
          id: this.dialogList.id,
          // apartmentId: this.dialogList.apartmentID,
          location: this.dialogList.location
        };
        this.post("device-service/L2/update", dto).then(res => {
          this.$message({
            message: "编辑安装位置成功!",
            type: "success"
          });
          this.$refs.myList.get();
          this.dialogVisible = false;
        });
        // }
      }
    }
  };
</script>
<style lang="scss">
  .alertTiltes {
    .ul_div {
      display: flex;
      line-height: 40px;
      font-family: "微软雅黑";
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: #666666;
      margin-bottom: 10px;
    }

    .ul_li_one {
      width: 85px;
      height: 40px;
      text-align: right;
    }

    .ul_li_scond {
      width: 160px;
      height: 40px;
      text-align: center;
    }

    .ul_li_thrwe {
      width: 310px;
      text-align: left;

      .img_div {
        width: 70px;
        height: 70px;
        float: left;
        border: 1px solid #dcdfe6;
        margin-right: 5px;
        margin-bottom: 5px;

        img {
          width: 100%;
          height: 100%;
          vertical-align: top;
        }
      }
    }

    .textarea {
      width: 310px;
      height: 80px;
      border-color: #dcdfe6;
      color: #303133;

      textarea {
        width: 100%;
        height: 100%;
        resize: none;
      }
    }
  }
</style>